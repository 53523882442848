import { z } from 'zod';
import { OrderApiSchema, OrderFromApi } from './order';
import { Company } from './company';
import { CartApiSchema, CartFromApi } from './cart';
import {
  SmartOrderErrorEnum,
  SmartOrderValidationRules,
  SmartOrderStatus,
} from '@startcatalog/catalog-types-package';
import { UserApiSchema, User } from './user';

export enum DraftOrderTypeEnum {
  QUOTE = 'quote',
  DOCUMENT = 'document',
  QUOTE_REQUEST = 'quote_request',
  PURCHASE_ORDER = 'purchase_order',
}

export const DraftOrderFromApiSchema = z.object({
  id: z.string(),
  status: z.enum(['open', 'completed']).default('open'),
  display_id: z.union([z.string(), z.number()]),
  cart_id: z.nullable(z.string()).optional(),
  order_id: z.nullable(z.string()).optional(),
  canceled_at: z.nullable(z.string()).optional(),
  completed_at: z.nullable(z.string()).optional(),
  no_notification_order: z.nullable(z.boolean().optional()),
  idempotency_key: z.nullable(z.string()).optional(),
  created_at: z.string(),
  updated_at: z.nullable(z.string()),
  metadata: z.nullable(z.record(z.unknown())).optional(),
  order: OrderApiSchema.optional(),
  cart: CartApiSchema.optional(),
  type: z.nativeEnum(DraftOrderTypeEnum).optional(),
  assignee_id: z.nullable(z.string()).optional(),
  assignee: UserApiSchema.optional(),
});

export type DraftOrderFromApi = z.infer<typeof DraftOrderFromApiSchema>;

export enum DraftOrderStatusEnum {
  OPEN = 'open',
  COMPLETED = 'completed',
}

export type SmartOrderAnalysisError = {
  type: SmartOrderErrorEnum;
  target?: string; // lineID
  data?: Record<string, unknown>;
};

export interface ValidationRules extends SmartOrderValidationRules {
  failed?: SmartOrderErrorEnum[];
  processing?: SmartOrderErrorEnum[];
}

export enum SmartOrderCorrectionTypeEnum {
  ADD_COMPANY = 'add_company',
  ADD_ORDER_ID = 'add_order_id',
  EDIT_LINE_ITEMS = 'edit_line_items',
}
export type SmartOrdersColumnData = {
  type: SmartOrderStatus;
  title: string;
  titleBadgeType: 'red' | 'green' | 'blue' | 'gray' | 'orange';
  icon: React.ReactNode;
  tooltip?: string;
  description: string;
  buttonLabel?: string;
  smartOrders: SmartOrder[]; // to type correctly when true data-model is known
  testId: string; // to retrieive column on tests
};

export type SmartOrder = {
  id: string;
  orderId?: string;
  status: SmartOrderStatus;
  createdAt: Date;
  updatedAt?: Date;
  displayId?: string; // catalog id
  externalId?: string; // order id from the buyer
  buyerCompany?: Company;
  purchaseUrl: string;
  buyerEmail?: string;
  senderEmail?: string;
  items: SmartOrderItemRow[];
  cart: CartFromApi;
  order?: OrderFromApi;
  errors?: SmartOrderAnalysisError[];
  expectedLineCount: number;
  smartOrderType: DraftOrderTypeEnum;
  debugInfo?: SmartOrderDebugInfo;
  assignee?: SmartOrderAssignee;
  isToComplete: boolean;
  isFailed: boolean;
  isProcessing: boolean;
  isSent: boolean;
};

export type SmartOrderItemRow = {
  id: string;
  rank: number;
  reference: string;
  title: string;
  thumbnail: string | null;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  displayUnit: string | null;
  storageUnit: string | null;
  decimal: boolean;
};

export type SmartOrderDebugInfo = {
  isTest: boolean;
  logsUrl: string;
  failureCauses: string[];
  technicalError?: unknown;
};

export function isSmartOrder(data: unknown): data is SmartOrder {
  if (!data || typeof data !== 'object') {
    return false;
  }
  return 'smartOrderType' in data;
}

export type SmartOrderAssignee = User & {
  initials: string;
  colorClass: string;
};
