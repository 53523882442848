'use client';

import { createContext, useContext, useEffect, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { User } from 'iconoir-react';
import { useTenant } from './tenant-context';
import { useGlobalData } from '../data/data';
import { datadogRum } from '@datadog/browser-rum';
import { useAmplitudeContext } from '@/lib/context/analytics-context';

interface User {
  id: string;
  name?: string;
  email: string;
  firstName?: string;
  lastName?: string;
  role?: string;
}

interface ILoggedUser extends User {
  impersonate?: User;
  isDebugModeActive: boolean;
}

const initialData = {
  isLoggedIn: false,
  id: '',
  name: '',
  email: '',
  firstName: '',
  lastName: '',
  isDebugModeActive: false,
};

export const AccountContext = createContext<ILoggedUser>(initialData);

export const AccountProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [loggedUser, setLoggedUser] = useState<ILoggedUser>(initialData);
  const router = useRouter();
  const pathName = usePathname();
  const { backendUrl, tenantId } = useTenant();
  const { getLoggedUser } = useGlobalData();
  const { identifyAmplitudeUser } = useAmplitudeContext();
  const protectedPaths = [
    '/',
    '/products',
    '/customers',
    '/reports',
    '/orders',
    '/users',
    '/smartorder',
    '/home',
  ];

  useEffect(() => {
    // I need to get the backend url from the tenant store
    const manageLogin = async () => {
      try {
        if (isProtectedPath(pathName)) {
          const user = await getLoggedUser();
          if (user) {
            const name = `${user.firstName} ${user.lastName}`;
            const isDebugModeActive =
              !process.env.NEXT_PUBLIC_DISABLE_DEBUG_MODE &&
              user.email === 'help@startcatalog.com';
            setLoggedUser({
              ...user,
              name,
              isDebugModeActive,
            });
            identifyAmplitudeUser?.(user.id);
          } else {
            const rootUrl = `${window.location.protocol}//${window.location.host}`;
            router.push(`${backendUrl}/admin/auth/auth0?returnTo=${rootUrl}`);
          }
        }
      } catch (error) {
        console.error('Logged user is incorrect', error);
      }
    };

    manageLogin();
  }, []);

  useEffect(() => {
    if (loggedUser?.id && tenantId) {
      datadogRum.setUser({
        id: loggedUser.id,
        email: loggedUser.email,
        name: loggedUser.name,
        sellerName: tenantId,
      });
    }
  }, [loggedUser, tenantId]);

  const isProtectedPath = (pathname: string) => {
    return protectedPaths.some(
      (protectedPath) =>
        pathname === protectedPath || pathname.startsWith(`${protectedPath}/`),
    );
  };

  return (
    <AccountContext.Provider value={loggedUser}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);

  if (context === null) {
    throw new Error('useAccount must be used within a AccountProvider');
  }
  return context;
};
